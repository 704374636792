




















import { Component, Mixins, Prop } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { MenuItem } from "@/components/Menu/menu-item";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import menu_optionModule from "@/store/modules/menu_option-module";

@Component({
  components: {},
})
export default class MenuFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: MenuItem = new MenuItem();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await menu_optionModule.getmenu_option(this.getparamId().Parametro);
      this.item_pantalla = menu_optionModule.menu_option;
    }

    await menu_optionModule.getmenu_options();
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.icon_fntaws,
      "Icono",
      "icon_fntaws",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.text,
      "Nombre",
      "text",
      datatypes.maxlength
    )
      .isRequired()
      .Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.color,
      "Color",
      "color",
      60
    )
      .isRequired()
      .Dimesiones(12, 4, 2);
    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.url_fija,
      "Url",
      "url_fija",
      1,
      "Poner la url fija",
      "Concatenar con el padre"
    ).Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.link,
      "Link",
      "link",
      datatypes.maxlength
    )
      .isRequired()
      .Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.permission,
      "Permiso",
      "permission",
      60
    ).Dimesiones(12, 6, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.orden,
      "Orden",
      "orden"
    ).Dimesiones(12, 6, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_padre,
      "Padre",
      "id_padre"
    )
      .CreateComboSingleCodigoDescripcionNames(
        menu_optionModule.menu_options,
        "text",
        "id"
      )
      .Dimesiones(12, 6, 4);
  }

  public Insertar(object: MenuItem) {
    menu_optionModule.guardarmenu_option(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: MenuItem) {
    menu_optionModule.modificarmenu_option(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
